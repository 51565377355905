import React, { Component } from 'react'

const loading = require('../../img/loading/eyeball.gif')

const App =() => {
  return(
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', backgroundColor: 'black'}}>
      <h1 style={{fontFamily: 'Creepster', color: 'red'}}>A Good Scare</h1>
      <img src = { loading } style={{width:'250px', height:'250px'}} alt="loading image"/>
      <h1 style={{fontFamily: 'Creepster', color: 'red'}}>Watch this space.</h1>
    </div>
  )
}

export default App
